<template lang="">
  <div id=" #printarea" class="resume-main">
    <article name="resume-body" class="resume-body">
      <section name="profile" class="resume-section">
        <b-container>
          <div class="download-button">
            <b-button
              variant="success"
              href="RezaZokaei_Resume.pdf"
              target="_blank"
            >
              <b-icon icon="download" aria-hidden="true" size="lg"></b-icon>
              Download resume in PDF
            </b-button>
          </div>
          <b-row>
            <b-col>
              <h2>Profile</h2>
              <p>{{ resume.profile }}</p>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!------------- Professional experiences (start) ----------->

      <section name="professional-experience" class="resume-section">
        <b-container>
          <b-row>
            <b-col>
              <h2>Professional Experience</h2>
            </b-col>
          </b-row>
          <b-row
            v-for="(experience, index) in resume.experiences"
            :key="index"
            class="mt-5"
          >
            <b-col>
              <b-row>
                <b-col>
                  <h3>{{ experience.company }}</h3>
                </b-col>
                <b-col>
                  <h3>{{ experience.location }}</h3>
                </b-col>
                <b-col>
                  <h3>
                    {{
                      date_fromto(experience.start_date, experience.end_date)
                    }}
                  </h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="company_role">
                  <h4>{{ experience.role }}</h4>
                </b-col>
              </b-row>

              <b-row
                v-for="(project, index) in experience.projects"
                :key="index"
                class="mt-3 pl-3"
              >
                <b-row>
                  <b-col>
                    <b>{{ project.short_description }} </b>({{
                      date_fromto(project.start_date, project.end_date)
                    }})
                  </b-col>
                </b-row>
                <b-row class="pl-3">
                  <b-col><span v-html="project.details"></span> </b-col
                ></b-row>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!------------- Professional experiences (end) ----------->
      <!-------------     Other Section (start)     ----------->
      <section
        v-for="(section, index) in resume.sections"
        :key="index"
        name="other-sections"
        class="mt-5 resume-section"
      >
        <b-container>
          <b-row>
            <b-col>
              <h2>
                {{ section.section_title }}
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-row
                v-for="(subsection, index) in section.subsections"
                :key="index"
                class="mb-2"
              >
                <b-col>
                  <b-row>
                    <b-col>
                      <b>
                        {{ subsection.title }}| {{ subsection.role }} |
                        {{
                          date_fromto(
                            subsection.start_date,
                            subsection.end_date
                          )
                        }}
                      </b>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="ml-4">
                      <span v-html="subsection.description"></span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-------------     Other Section (end)     ----------->
      <!-------------     Education  (start)     ------------>
      <section name="education" class="resume-section">
        <b-container>
          <b-row>
            <b-col>
              <h2>Education</h2>
            </b-col>
          </b-row>
          <b-row
            v-for="(education, index) in resume.education"
            :key="index"
            class="mb-2"
          >
            <b-col>
              <b-row>
                <b-col>
                  <h4>
                    <b>
                      {{ education.degree_in }} | {{ education.name }} |
                      {{
                        date_fromto(education.start_date, education.end_date)
                      }}
                    </b>
                  </h4>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="ml-4">
                  Related coursework: {{ education.course_work }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!--------------     Education  (end)     ------------->
      <!--------------     Skills  (Start)     -------------->
      <section name="skills" class="resume-section">
        <b-container>
          <b-row>
            <b-col>
              <h2>Skills</h2>
            </b-col>
          </b-row>
          <b-row
            v-for="(skill, index) in resume.skills"
            :key="index"
            class="mb-1"
          >
            <b-col>
              <b-row class="skills-inline">
                <b-col>
                  <p class="skills">
                    <b>{{ skill.category }}:</b>
                    <span
                      v-for="(skillname, index) in skill.skills"
                      :key="index"
                    >
                      {{ skillname }}
                      <span v-if="index + 1 < skill.skills.length">,</span>
                    </span>
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!--------------     Skills  (End)     -------------->
    </article>
    <div class="download-button">
      <b-container>
        <b-row>
          <b-col cols="12" md="6">
            <b-button
              variant="success"
              href="RezaZokaei_Resume.pdf"
              target="_blank"
            >
              <b-icon icon="download" aria-hidden="true" size="lg"></b-icon>
              Download resume in PDF
            </b-button>
          </b-col>
          <b-col>
            <b-button @click="$router.push('/')"> Return home </b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import ResumeData from "../resume.json";
export default {
  data: () => {
    return {
      resume: ResumeData,
    };
  },
  created() {
    this.updatePageTitle("About");
  },
  methods: {
    date_fromto(start, end) {
      if (start && !end) return start;
      if (!start && end) return end;
      if (start && end) return start + " - " + end;
      return "";
    },
  },
};
</script>
<style lang="scss">
.resume-main {
  background: #02011a;
}
.resume-body {
  background: rgb(240, 240, 240);
  color: black;
  text-align: left;
  border: solid 1px #333;
}
.resume-section {
  margin: 2rem;

  padding-right: 2rem;
  padding-left: 2rem;
  padding-bottom: 2rem;
  h2 {
    font-size: 700;
    padding-left: 2rem;
    border-bottom: solid 2px black;
    margin-bottom: 2rem;
  }
  .skills-inline {
    padding-left: 6em;
    text-indent: -6em;
  }

  li {
    margin-left: 30px;
  }
  .company_role {
    text-align: center;
  }
}
.download-button {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 2rem;
  justify-content: center;
  a,
  button {
    margin-top: 2rem;
    margin-left: 0.5rem;
  }
}
@media screen and (max-width: 500px) {
  .resume-main {
    .resume-section {
      font-size: 0.9rem;
      margin: 0.5rem;
      padding-right: 0.2rem;
      padding-left: 0.2rem;
    }
    h2 {
      font-size: 1.2rem;
      padding-left: 0.2rem;
    }
    h3 {
      font-size: 1rem;
    }
    h4 {
      font-size: 0.85rem;
    }
  }
}
@media screen and (min-width: 900px) {
  .resume-section {
    font-size: 1rem;
    margin: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  .resume-main {
    h2 {
      padding-left: 2rem;
      font-size: 2rem;
    }
    h3 {
      font-size: 1.75rem;
    }
    h4 {
      font-size: 1.25rem;
    }
  }
}

// @media print {
//   #site-header *,
//   #site-footer * {
//     display: none;
//   }
// }
</style>
